import React, { useEffect, useRef, useState } from "react";
import TopSection from "../Components/TopSection/TopSection";
import classes from "./MainPage.module.scss";
import Products from "../Components/Products/Products";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap-trial/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ReactLenis, useLenis } from "@studio-freight/react-lenis";
import WhoWeAre from "../Components/WhoWeAre/WhoWeAre";
import TheVision from "../Components/TheVision/TheVision";
import BigDreams from "../Components/TheVision/BigDreams";
import Brands from "../Components/Brands/Brands";
import JoinUs from "../Components/JoinUs/JoinUs";
import ContactUs from "../Components/ContactUs/ContactUs";
import logo from "../assets/NaarIntLogo.png";
import Modal from "react-modal";
import FormsModal from "../Components/Forms/FormsModal";
import menu from "../assets/HeaderFooter/bMenu.png";

gsap.registerPlugin(useGSAP, ScrollTrigger, ScrollToPlugin);

ScrollTrigger.normalizeScroll(true);
Modal.setAppElement("#root");

const customStyles = {
    overlay: {
        zIndex: 999999999999999, // Ensure this is higher than other elements on the page
        backgroundColor: "rgba(0, 0, 0)", // Optional: darken the background
    },
    content: {
        height: "100vh",
        width: "100vw",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        // marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#000",
        zIndex: 999999,
        overflow: " auto",
    },
};

interface IMainPageProps {}
const MainPage: React.FC<IMainPageProps> = () => {
    const [tl, setTl] = useState<gsap.core.Timeline>();
    const [showVideo, setShowVideo] = useState<boolean>(true);
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [form, setForm] = React.useState<string>("");

    const allContainer = useRef<HTMLDivElement>(null);
    const pageRef = useRef<HTMLDivElement>(null);
    const whoWeAreRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const visionRef = useRef<HTMLDivElement>(null);
    const ourMissionRef = useRef<HTMLDivElement>(null);
    const portfolioRef = useRef<HTMLDivElement>(null);
    const teamRef = useRef<HTMLDivElement>(null);
    const contactRef = useRef<HTMLDivElement>(null);
    const [showMenu, setShowMenu] = useState<boolean>(false);

    useGSAP(
        () => {
            setTl(tl);
            ScrollTrigger.refresh();
        },
        { scope: pageRef }
    );

    useEffect(() => {
        window.addEventListener("load", () => {
            // console.log("refreshing 1");
            ScrollTrigger.refresh();
        });

        return () =>
            window.removeEventListener("load", () => {
                ScrollTrigger.refresh();
            });
    }, []);

    React.useEffect(() => {
        // Refresh ScrollTrigger after all content is loaded
        const resizeListener = () => {
            console.log(window.innerWidth);

            if (window.innerWidth >= 750 && showMenu) {
                setShowMenu(false);
            }
        };

        window.addEventListener("resize", resizeListener);

        return () => {
            window.removeEventListener("resize", resizeListener);
        };
    }, []);

    React.useEffect(() => {
        changeSection(5, true);

        return () => {};
    }, []);

    const lenis = useLenis(({ scroll }) => {
        // called every scroll
    });

    const onVideoEnded = () => {
        // getUpdateParameters("down", 1);
        ScrollTrigger.refresh();
        setShowVideo(false);
        changeSection(5, false);
    };

    const openFormModal = (f: string) => {
        setForm(f);
        setModalOpen(true);
    };

    const toggleMenu = (event: React.MouseEvent<HTMLDivElement>): void => {
        setShowMenu((prev) => !prev);
        event.preventDefault();
    };

    const changeSection = (sectionNumber: number, immediate?: boolean) => {
        if (showMenu) {
            setShowMenu(false);
        }
        if (sectionNumber === 0) {
            gsap.to(window, {
                duration: 1, // 1 second scroll animation
                scrollTo: {
                    y: whoWeAreRef.current ? whoWeAreRef.current : "", // Scroll to the ref
                    offsetY: -window.innerHeight * 2, // Scroll 50px past the element (negative means scrolling past)
                }, // Scroll to the ref
                ease: "power2.out", // Ease for a smooth effect
            });
        } else if (sectionNumber === 1) {
            gsap.to(window, {
                duration: 1, // 1 second scroll animation
                scrollTo: {
                    y: visionRef.current ? visionRef.current : "", // Scroll to the ref
                    offsetY: -window.innerHeight * 0.3, // Scroll 50px past the element (negative means scrolling past)
                }, // Scroll to the ref
                ease: "power2.out", // Ease for a smooth effect
            });
        } else if (sectionNumber === 2) {
            gsap.to(window, {
                duration: 1, // 1 second scroll animation
                scrollTo: {
                    y: ourMissionRef.current ? ourMissionRef.current : "", // Scroll to the ref
                    offsetY: -100, // Scroll 50px past the element (negative means scrolling past)
                }, // Scroll to the ref
                ease: "power2.out", // Ease for a smooth effect
            });
        } else if (sectionNumber === 3) {
            gsap.to(window, {
                duration: 1, // 1 second scroll animation
                scrollTo: {
                    y: teamRef.current ? teamRef.current : "", // Scroll to the ref
                    offsetY: 10, // Scroll 50px past the element (negative means scrolling past)
                }, // Scroll to the ref
                ease: "power2.out", // Ease for a smooth effect
            });
        } else if (sectionNumber === 4) {
            gsap.to(window, {
                duration: 1, // 1 second scroll animation
                scrollTo: {
                    y: contactRef.current ? contactRef.current : "", // Scroll to the ref
                    offsetY: 100, // Scroll 50px past the element (negative means scrolling past)
                }, // Scroll to the ref
                ease: "power2.out", // Ease for a smooth effect
            });
        } else if (sectionNumber === 5) {
            gsap.to(window, {
                duration: immediate ? 0 : 1, // 1 second scroll animation
                scrollTo: {
                    y: 0, // Scroll to the ref
                    offsetY: -window.innerHeight * 7.8, // Scroll 50px past the element (negative means scrolling past)
                }, // Scroll to the ref
                ease: "power2.out", // Ease for a smooth effect
            });
        } else if (sectionNumber === 6) {
            gsap.to(window, {
                duration: immediate ? 0 : 1, // 1 second scroll animation
                scrollTo: {
                    y: portfolioRef.current ? portfolioRef.current : "",
                    offsetY: 80, // Scroll 50px past the element (negative means scrolling past)
                }, // Scroll to the ref
                ease: "power2.out", // Ease for a smooth effect
            });
        }
    };

    // const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
    // const FadeUp = batch(Fade(), Move(), Sticky());
    return (
        <main className={classes.main__page} ref={pageRef}>
            {/* <div
        style={{
          position: "sticky",
          zIndex: 999999999999999999999999999999999999999999999999999,
        }}
      > */}
            <FormsModal
                modalOpen={modalOpen}
                closeModal={() => {
                    setModalOpen(false);
                    changeSection(5, true);
                }}
                form={form}
            />
            {/* </div> */}
            <Modal
                isOpen={showVideo}
                //   onAfterOpen={afterOpenModal}
                onRequestClose={() => setShowVideo(false)}
                style={customStyles}
                contentLabel="Example Modal">
                <div
                    className={classes.formContainer}
                    onWheel={(e) => {
                        e.stopPropagation();
                    }}>
                    {/* <div className={classes.left}>Contact us</div> */}
                    <div className={classes.right}>
                        <TopSection onVideoEnded={onVideoEnded} />
                    </div>
                </div>
            </Modal>
            <ReactLenis root>
                {!showVideo && !modalOpen && (
                    <header className="blurry-background">
                        {showMenu && (
                            <div className="dropdown__section">
                                <div onClick={() => changeSection(0)}>Who We Are</div>
                                <div onClick={() => changeSection(1)}>Our vision</div>
                                <div onClick={() => changeSection(2)}>Our Team</div>
                                <div onClick={() => changeSection(6)}>Our Portfolio</div>
                                <div onClick={() => changeSection(4)}>Get in touch</div>
                            </div>
                        )}
                        <div className="side__sections side__section1" onClick={() => changeSection(5)}>
                            <img src={logo} className={classes.logo_img} alt="logo" />
                        </div>
                        <div className="middle__section">
                            <div className="links" onClick={() => changeSection(0)}>
                                Who We Are
                            </div>
                            <div className="links" onClick={() => changeSection(1)}>
                                Our Vision
                            </div>
                            <div className="links" onClick={() => changeSection(2)}>
                                Our Team
                            </div>
                            {/* <div className="links" onClick={() => changeSection(3)}>
                Our Team
              </div> */}
                            <div className="links" onClick={() => changeSection(6)}>
                                Our Portfolio
                            </div>
                            <div className="links" onClick={() => changeSection(4)}>
                                Get In Touch
                            </div>
                        </div>
                        <div className="side__sections side__section2">
                            <div className="icon__container" onClick={(e) => toggleMenu(e)} ref={menuRef}>
                                <img src={menu} alt="menu_icon" />
                            </div>
                        </div>
                    </header>
                )}

                <div ref={allContainer}>
                    <Products ptl={tl} />
                    {/* <div className={classes.main__page__spacer}></div> */}
                    <div ref={whoWeAreRef}>
                        <WhoWeAre ptl={tl} />
                    </div>
                    {/* <div className={classes.main__page__spacer__2}></div> */}
                    <div ref={visionRef}>
                        <TheVision ptl={tl} />
                    </div>
                    <div ref={ourMissionRef}>
                        <BigDreams ptl={tl} />
                    </div>
                    <div ref={portfolioRef}>
                        <Brands />
                    </div>
                    {/* <div ref={teamRef}>
            <JoinUs ptl={tl} openFormModal={openFormModal} />
          </div> */}
                    {/* <div className={classes.main__page}></div> */}
                </div>
            </ReactLenis>
            <div ref={contactRef}>
                <ContactUs openFormModal={openFormModal}>
                    <footer className={classes.footer}>
                        <div className={classes.left}>
                            <div onClick={() => changeSection(5)}>
                                <img src={logo} className={classes.logo_img} alt="logo" />
                            </div>
                            <div className={classes.links__container}>
                                <div onClick={() => changeSection(0)}>Who We Are</div>
                                <div onClick={() => changeSection(1)}>Our Vision</div>
                                <div onClick={() => changeSection(2)}>Our Team</div>
                                <div onClick={() => changeSection(6)}>Our Portfolio</div>

                                {/* <div onClick={() => changeSection(3)}>Team</div> */}
                                {/* <div onClick={() => changeSection(4)}>Get In Touch</div> */}
                            </div>
                        </div>
                        <div className={classes.right}>© 2024 NAAR International. All rights reserved. Unauthorized use is prohibited.</div>
                    </footer>
                </ContactUs>
            </div>

            {!showVideo && !modalOpen && <div className={classes.scroll__down}>&#8811;</div>}
        </main>
    );
};

export default MainPage;
