import React, { useRef } from "react";
import classes from "./TheVision.module.scss";
import Top from "../../assets/TheVision/Top.png";
// import Bottom from "../../../assets/TheVision/Bottom.png";
import Eye from "../../assets/TheVision/Eye.png";
// import BigDreams from "./BigDreams";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap-trial/ScrollTrigger";
import AnimatedPersons from "./AnimatedPersons";
import Bottom from "../../assets/TheVision/Bottom.png";

gsap.registerPlugin(useGSAP, ScrollTrigger);
ScrollTrigger.normalizeScroll(true);

const data: string[] = [
  "Big Dreams,",
  "Real People",
  `Our team is made up of highly motivated and complementary people
  with international backgrounds (we speak more than 7 languages!)
  that always pour their hearts into what they do to come up with
  innovative & creative ideas.Building on our diversity, with
  experience in different departments ranging from world-renowned
  companies such as Philip Morris, Al Fakher, Saatchi & Saatchi, Leo
  Burnett, Drive Dentsu, to successful start-ups, together, we have
  created a well-rounded, dynamic, professional and fun environment
  that brings out both creativity and intelligence.`,
];

interface ITheVisionProps {
  ptl: gsap.core.Timeline | undefined;
}
const TheVision: React.FC<ITheVisionProps> = ({ ptl }) => {
  const visionRef = useRef<HTMLDivElement>(null);
  useGSAP(() => {
    // let tl = gsap.timeline({});
    // alert(1);

    /**
     * Change section background
     */

    const tl = gsap.timeline();

    ScrollTrigger.create({
      trigger: visionRef.current,
      start: "top 70%",
      // start: "top top",
      end: `bottom 70%`,
      scrub: 1,
      animation: tl,
    });

    ScrollTrigger.create({
      trigger: visionRef.current,
      start: "top top", // Pin when the element reaches the top of the viewport
      end: "+=100", // Adjust this value to control how long the element stays pinned
      pin: true,
      pinSpacing: true, // Adjust to true or false based on your spacing needs
    });

    tl.from(".image__container", {
      y: 250,
      ease: "power.out",
      // onComplete: sectionTitle.play(),
      // scrollTrigger: {
      //   trigger: ".image__container",
      //   start: "top 90%",
      //   end: `bottom 70%`,
      //   scrub: true,
      // },
    });

    tl.from(
      ".header__text",
      {
        x: -100,
        opacity: 0.2,
        // ease: "power.out",
        // onComplete: sectionTitle.play(),
        // scrollTrigger: {
        //   trigger: ".header__text",
        //   start: "top bottom",
        //   end: `bottom 80%`,
        //   scrub: true,
        // },
      },
      "<"
    );
    tl.from(
      ".content__text",
      {
        x: -100,
        opacity: 0.6,
        // ease: "power.out",
        // onComplete: sectionTitle.play(),
        // scrollTrigger: {
        //   trigger: ".content__text",
        //   start: "top bottom",
        //   end: `bottom 80%`,
        //   scrub: true,
        // },
      },
      "<0.1"
    );

    ptl?.add(tl);
  }, [ptl]);

  return (
    <>
      <section className={classes.the__vision} ref={visionRef}>
        <div className={`${classes.top__image}`}>
          <img src={Top} alt="" />
        </div>

        <div className={classes.content__container}>
          <div className={`image__container ${classes.left}`}>
            <img src={Eye} alt="" />
          </div>
          <div className={classes.right}>
            <div className={`header__text ${classes.vision__header}`}>
              The Vision
            </div>
            <div className={`content__text ${classes.vision__content}`}>
              NAAR’s ambition is to grow with our partners and clients to become
              a large international group of companies with leading unique
              brands, products and offerings around the world. We aim for
              innovation and differentiation not only in our product
              development, but also in the way we approach every aspect of our
              business and its environment.
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TheVision;
