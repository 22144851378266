import React, { useRef, useState } from "react";
import zengaz from "../../assets/LightersZenngaz.png";
import classes from "./products.module.scss";
import naar from "../../assets/PizzaLighter.png";
import naar1 from "../../assets/Pool_Party.png";
import logo from "../../assets/NaarIntLogo.png";
import WhoWeAre from "../WhoWeAre/WhoWeAre";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap-trial/ScrollTrigger";

gsap.registerPlugin(useGSAP, ScrollTrigger);
ScrollTrigger.normalizeScroll(true);

interface IProductsProps {
  ptl: gsap.core.Timeline | undefined;
}
const Products: React.FC<IProductsProps> = ({ ptl }) => {
  const container = useRef<HTMLDivElement>(null);
  const productsRef = useRef<HTMLDivElement>(null);
  const whoweareRef = useRef<HTMLDivElement>(null);
  const zengaz_image = useRef<HTMLDivElement>(null);
  const header1Ref = useRef<HTMLDivElement>(null);
  const header2Ref = useRef<HTMLDivElement>(null);
  const brandZengazRef = useRef<HTMLDivElement>(null);

  // const tl = useRef<any>(null);

  useGSAP(() => {
    const tl = gsap.timeline();

    ScrollTrigger.create({
      trigger: productsRef.current,
      pin: productsRef.current,
      start: "top top",
      // start: "top top",
      end: `+=${window.innerHeight * 20}`,
      scrub: 1,
      pinSpacing: true,
      animation: tl,
    });

    /**
     * Unload Logo
     */
    tl &&
      tl.to(".logo", {
        opacity: 0,
        scale: 0,
        duration: 50,
      });

    /**
     * Load meet our iconic brands text
     */
    tl &&
      tl.from(".text__header", {
        x: -150,
        y: 150,
        opacity: 0,
        scale: 0.6,
        duration: 50,
      });

    /**
     * Load Zengaz
     * Load images
     */
    tl &&
      tl
        .from(".text__zengaz", {
          x: -150,
          opacity: 0,
          duration: 50,
        })
        .from(
          zengaz_image.current,
          {
            y: "70vh",
            scale: 0.8,
            duration: 50,
          },
          "<"
        );

    /**
     * Remove zengaz
     * Remove zengaz images
     */
    tl &&
      tl
        .to(".text__zengaz", {
          x: 1000,
          duration: 50,
        })
        .to(
          zengaz_image.current,
          {
            y: "-95vh",
            duration: 50,
          },
          "<"
        );

    /**
     * Load Naar text
     * Load Naar images (x2)
     */
    tl &&
      tl
        .from(".text__naar", {
          x: -550,
          opacity: 0,
          duration: 50,
        })
        .from(
          ".pool__party",
          {
            y: "75vh",
            duration: 50,
          },
          "<"
        )
        .from(
          ".naar_img",
          {
            y: "75vh",
            duration: 50,
          },
          "<"
        );

    /**
     * Remove Pool Party
     * Animate Naar pizza lighter image
     */

    tl &&
      tl
        .to(".naar_img", {
          left: "50%",
          xPercent: -30,
          yPercent: 30,
          duration: 50,
        })
        .to(
          ".pool__party",
          {
            y: "-90vh",
            x: "60vw",
            duration: 50,
          },
          "<"
        )
        .to(
          ".text__header",
          {
            y: "-70vh",
            duration: 50,
          },
          "<"
        )
        .to(
          ".text__naar",
          {
            y: "-70vh",
            duration: 50,
          },
          "<"
        );

    tl &&
      tl.to(".naar_img", {
        left: "30%",
        xPercent: -30,
        yPercent: 50,
        scale: 1.5,
        rotate: "5deg",
        duration: 50,
      });

    tl &&
      tl
        .to(".naar_img", {
          left: "55%",
          xPercent: -25,
          yPercent: 900,
          scale: 25,
          opacity: 0,
          rotate: "10deg",
          duration: 30,
        })
        .to(
          productsRef.current,
          {
            backgroundColor: "transparent",
            opacity: 1,
            duration: 30,
          },
          "<"
        )
        .to(
          ".ps ",
          {
            backgroundColor: "transparent",
            opacity: 1,
            duration: 30,
          },
          "<"
        );

    /**
     * Fade and remove
     */
    // tl &&
    //   tl
    //     .to(productsRef.current, {
    //       // backgroundColor: "#000",
    //       height: "0px",
    //       duration: 0,
    //     })
    //     .to(
    //       ".naar_img",
    //       {
    //         opacity: 0,
    //         duration: 0,
    //       },
    //       "<"
    //     );

    ptl?.add(tl);
  }, [ptl]);

  return (
    <div ref={container}>
      <div ref={productsRef} className={`ps ${classes.page__section}`}>
        <img
          src={logo}
          alt="Logo"
          className={`logo ${classes.company__logo}`}
        />
        <div className={classes.left__column}>
          <div className={classes.image__container} ref={zengaz_image}>
            <img
              src={zengaz}
              alt="zengaz"
              className={`${classes.prod__img__dn}`}
            />
          </div>
        </div>
        <div className={classes.right__column}>
          <div className={classes.text__container}>
            <div ref={header1Ref} className={`text__header`}>
              Meet Our
            </div>
            <div ref={header2Ref} className={`text__header`}>
              Iconic Brands
            </div>
            <div className={classes.second__row_container}>
              <div
                ref={brandZengazRef}
                className={`text__zengaz ${classes.second__row}`}
              >
                ZENGAZ
              </div>
              <div
                ref={brandZengazRef}
                className={`text__naar ${classes.second__row}`}
              >
                NAAR
              </div>
            </div>
          </div>
        </div>
        <img
          src={naar1}
          alt="naar1"
          className={`pool__party ${classes.pool__party__dn}`}
        />
        <img
          src={naar}
          alt="naar"
          className={`naar_img ${classes.image__move__and__scale__dn}`}
        />
      </div>
    </div>
  );
};

export default Products;
