import React from "react";
import classes from "./TopSection.module.scss";
const intro = require("../../assets/Home/Sequence 01.mp4");

interface ITopSectionProps {
  onVideoEnded: () => void;
}

const TopSection: React.FC<ITopSectionProps> = ({ onVideoEnded }) => {
  return (
    <section className={classes.screen__sized__section}>
      <div className={classes.video__intro}>
        <video
          className=""
          autoPlay
          muted
          onEnded={() => onVideoEnded()}
          playsInline
        >
          <source src={intro} type="video/mp4" />
        </video>
      </div>
    </section>
  );
};

export default TopSection;
