import React from "react";
// import AnimatedPersons from "./AnimatedPersons";
// import AnimatedText from "./AnimatedText";
// import Brands from "./Brands";
import classes from "./TheVision.module.scss";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap-trial/ScrollTrigger";
import AnimatedPersons from "./AnimatedPersons";
import Bottom from "../../assets/TheVision/Bottom.png";
gsap.registerPlugin(useGSAP, ScrollTrigger);
ScrollTrigger.normalizeScroll(true);

const data: string[] = [
  "Big Dreams,",
  "Real People",
  `Our team is made up of highly motivated and complementary people
  with international backgrounds (we speak more than 7 languages!)
  that always pour their hearts into what they do to come up with
  innovative & creative ideas. Building on our diversity, with
  experience in different departments ranging from world-renowned
  companies such as Philip Morris, Al Fakher, Saatchi & Saatchi, Leo
  Burnett, Drive Dentsu, to successful start-ups, together, we have
  created a well-rounded, dynamic, professional and fun environment
  that brings out both creativity and intelligence.`,
];

interface IBigDreamsProps {
  ptl: gsap.core.Timeline | undefined;
}
const BigDreams: React.FC<IBigDreamsProps> = ({ ptl }) => {
  const bidDreamsRef = React.useRef<HTMLDivElement>(null);
  // React.useEffect(() => {
  //   // Refresh ScrollTrigger after all content is loaded
  //   const handleLoad = () => {
  //     ScrollTrigger.refresh();
  //   };

  //   window.addEventListener("load", handleLoad);

  //   return () => {
  //     window.removeEventListener("load", handleLoad);
  //   };
  // }, []);

  useGSAP(() => {
    // let tl = gsap.timeline({});
    // alert(1);

    /**
     * Change section background
     */

    const tl = gsap.timeline();

    ScrollTrigger.create({
      trigger: bidDreamsRef.current,
      start: "top bottom",
      // start: "top top",
      end: `top 45%`,
      scrub: true,
      animation: tl,
    });

    ScrollTrigger.create({
      trigger: bidDreamsRef.current,
      start: "top top", // Pin when the element reaches the top of the viewport
      end: "+=100", // Adjust this value to control how long the element stays pinned
      pin: true,
      pinSpacing: true, // Adjust to true or false based on your spacing needs
    });

    tl.from(".big__dreams__header__1", {
      x: -250,
      opacity: 0.2,
      // ease: "power.out",
      // onComplete: sectionTitle.play(),
      // scrollTrigger: {
      //   trigger: ".vision__page",
      //   start: "top bottom",
      //   end: `top 45%`,
      //   scrub: true,
      // },
    });

    tl.from(
      ".big__dreams__header__2",
      {
        x: -250,
        opacity: 0.2,
        // ease: "power.out",
        // onComplete: sectionTitle.play(),
        // scrollTrigger: {
        //   trigger: ".vision__page",
        //   start: "top bottom",
        //   end: `top 45%`,
        //   scrub: true,
        // },
      },
      "<0.1"
    );

    tl.from(
      ".big__dreams__content",
      {
        x: -250,
        opacity: 0.2,
        // ease: "power.out",
        // onComplete: sectionTitle.play(),
        // scrollTrigger: {
        //   trigger: ".vision__page",
        //   start: "top bottom",
        //   end: `top 45%`,
        //   scrub: true,
        // },
      },
      "<0.1"
    );
  }, [ptl]);

  return (
    <section
      ref={bidDreamsRef}
      className={`vision__page ${classes.big__greams__container}`}
    >
      <div className={classes.big__dreams}>
        <div className={classes.left}>
          <div
            className={`big__dreams__header__1 ${classes.big__dreams__header}`}
          >
            {data[0]}
          </div>
          <div
            className={`big__dreams__header__2 ${classes.big__dreams__header}`}
          >
            {data[1]}
          </div>
          <div
            className={`big__dreams__content ${classes.big__dreams__content}`}
          >
            {data[2]}
          </div>
        </div>
        <div className={classes.right}>
          {" "}
          <AnimatedPersons />{" "}
        </div>
      </div>

      <div className={classes.bottom__image}>
        <img src={Bottom} alt="" />
      </div>
    </section>
  );
};

export default BigDreams;
