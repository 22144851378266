import React, { useEffect } from "react";
import "./ContactUs.scss";
import headimg from "../../assets/ContactUs/Group 126.png";
import arrow from "../../assets/JoinUs/Vector.png";

interface IContactUsProps {
    openFormModal: (form: string) => void;
    children: any;
}

const ContactUs: React.FC<IContactUsProps> = ({ openFormModal, children }) => {
    useEffect(() => {
        // Listen for messages from the iframe
        const handleMessage = (event: MessageEvent) => {
            // Verify the origin for security
            if (event.origin !== "https://naarinternational.com") return;

            // Check if the message contains height information
            if (event.data && typeof event.data === "object" && "frameHeight" in event.data) {
                const iframe = document.querySelector(".contact-form-iframe") as HTMLIFrameElement;
                if (iframe) {
                    iframe.style.height = `${event.data.frameHeight}px`;
                }
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, []);

    return (
        <section className="contact__us__container">
            <div className="content__container">
                <img className="contact__img" src={headimg} alt="" />
                <div className="contact__us__header">Contact Us</div>

                <iframe
                    className="contact-form-iframe"
                    src="https://naarinternational.com/form/index.php"
                    style={{
                        width: "100%",
                        height: "500px",
                        border: "none",
                        maxHeight: "none",
                        minHeight: "500px",
                    }}
                    title="Contact Form"
                />

                {/*
                  <div
                    className="contact__us__send__message"
                    onClick={() => {
                      openFormModal("contact");
                    }}
                  >
                    Send a message{" "}
                    <img className="send__msg__img" src={arrow} alt="arrow" />
                  </div>
                */}
            </div>
            {children}
        </section>
    );
};

export default ContactUs;
